import eventListener from '@synergycms_core/middlewares/eventListener';

import actions from './store/actions';

export default eventListener("cmsengine", [{
  name : "enginePreview",
  effect : (data, { dispatch, getState }) => {
    const  windowConfig = window.engine_sygy_data ? window.engine_sygy_data : window.frontLoader['cms-engine'];
    const { hotel_id: hotId, hotels_info } = windowConfig;
    
    if(hotId && !hotels_info)
      dispatch(actions.app.config.getHotel(hotId, data));
    else
      dispatch(actions.app.config.get(data));
  }
},{
  name : "showModalEngine",
  effect : (data, { dispatch, getState }) => {
    if (typeof window.engine_sygy_data == 'object' && typeof window.engine_sygy_data.setNewState == 'function') {
      window.engine_sygy_data.setNewState({ showModalView: true });
    }else if (typeof window.frontLoader['cms-engine'] == 'object' && typeof window.frontLoader['cms-engine'].setNewState == 'function') {
      window.frontLoader['cms-engine'].setNewState({ showModalView: true });
    }
  }
},{
  name : "setNewMatchEngine",
  effect : (data, { dispatch, getState }) => {
    if (data && typeof window.engine_sygy_data == 'object' && typeof window.engine_sygy_data.setNewState == 'function') {
      window.engine_sygy_data.setNewMatch( data );
    }else if (data && typeof window.frontLoader['cms-engine'] == 'object' && typeof window.frontLoader['cms-engine'].setNewState == 'function') {
      window.frontLoader['cms-engine'].setNewMatch( data );
    }
  }
}]);


/*
const detail = {
    theme_values:{
      mainColor: 'blue', 
      backgroundEngine: 'red', 
    },
    max_childs: 0,
    max_rooms: 3,
};
            
const name = 'enginePreview';
const event2 = new CustomEvent(name, { detail });
window.document.dispatchEvent(event2);

window.document.dispatchEvent(new CustomEvent('showModalEngine'));
window.document.dispatchEvent(new CustomEvent('setNewMatchEngine', {detail: {hotelCode: "SYN548"} }));
*/